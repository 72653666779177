export const NEW_FORM_BTN_ID = 'new-form-cta';

export const openCreateEmailSignupFormDialog = () => {
  if (typeof window.PMW.formMaker === 'undefined') {
    window.PMW.getJsAndCssAssetGroup('formMakerDialog', openCreateDialog);
  } else {
    openCreateDialog();
  }
};

export const pollForEmailSignupFormPreview = (id: string) => {
  if (typeof window.PMW.formMaker === 'undefined') {
    window.PMW.getJsAndCssAssetGroup('formMakerDialog', pollForEmailSignupPreview.bind(this, id));
  } else {
    pollForEmailSignupPreview(id);
  }
};

export const openPublishEmailSignupFormDialog = (id: string) => {
  if (typeof window.PMW.formMaker === 'undefined') {
    window.PMW.getJsAndCssAssetGroup('formMakerDialog', openPublishDialog.bind(this, id));
  } else {
    openPublishDialog(id);
  }
};

function pollForEmailSignupPreview(id: string) {
  window.PMW.formMaker.grid.pollForEmailSignupFormPreview(id);
}

function openCreateDialog() {
  window.PMW.formMaker.createDialog.open();
}

function openPublishDialog(id: string) {
  window.PMW.formMaker.publishDialog.open(id);
}
