import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import {
  EmailCampaign,
  EmailCampaignsLazyData,
  EmailCampaignSorting,
  EmailCampaignSortingFilter,
  EmailCampaignState,
  EmailCampaignStatus,
  EmailCampaignStatusFilter,
  EmailMarketingCampaignPageType,
  EmailMarketingCampaignsReduxState,
  EmailPreviewPollFinishResponse,
} from '@Components/email-marketing-campaigns/email-marketing-campaigns.types';
import {formatLocalDate, getDateFromUnixTimestamp, getUnixTimestamp, isFutureDate, isInThirtyMinutesOrLessFromNow, isUpcomingDate} from '@Utils/date.util';
import {noop} from '@Utils/general.util';
import {LoadingStates} from '@Utils/loading.util';
import {sendScheduledCampaignNow, unscheduleEmailCampaign} from '@Components/email-marketing-campaigns/email-marketing-campaigns-thunk';
import {executeThunk} from '@Utils/thunk.util';
import {CoreJsModalButtons} from '@/types/core-js-modal-buttons';
import {onAjaxError} from '@Utils/ajax.util';
import {isUserPremium} from '@Libraries/user.library';

interface GetEmailTemplateHTMLResponse {
  html: string;
  fileName: string;
}

export const EMAIL_NON_PREVIEW_SKELETON_HEIGHT: number = 16;
export const EMAIL_STATUS_PILL_SKELETON_WIDTH: number = 62;
export const EMAIL_STATUS_PILL_SKELETON_HEIGHT: number = 26;

export const EMAIL_CAMPAIGNS_LAZY_BATCH_SIZE = 30;

const EMAIL_EXPORT_GA_LABEL_MY_STUFF = 'MyStuff';
const SEND_CAMPAIGN_NOW_MODAL_ID = 'send-campaign-now';
const UNSCHEDULE_CAMPAIGN_MODAL_ID = 'cancel-scheduled-campaign';
// the minimum number of minutes a campaign needs to be scheduled from now to be considered 'scheduled'
const MIN_SCHEDULE_MINUTES_FROM_NOW = 15;

export const myStuffContainerQuery: ContainerQueryObject = {
  xxsmall: {
    maxWidth: 400,
  },

  xsmall: {
    maxWidth: 510,
  },

  small: {
    minWidth: 511,
    maxWidth: 750,
  },
  medium: {
    minWidth: 751,
    maxWidth: 1024,
  },
  large: {
    minWidth: 1024,
  },
};

export const getCampaignsInitialLazyData = (): EmailCampaignsLazyData => {
  return {
    ids: [],
    loadingState: LoadingStates.NOT_LOADED,
    loadMore: true,
  };
};

export const getDummyEmailCampaign = (): EmailCampaign => {
  return {
    id: '',
    state: EmailCampaignState.DRAFT,
    subject: '',
    isScheduled: false,
    isPaused: false,
    isVideo: false,
    isDeleted: false,
    isEditable: false,
    imagePreviewURL: '',
    name: '',
    publishOn: 0,
    mailingListNames: [],
  };
};

export const isDeletedCampaignsViewType = (campaignViewType: EmailMarketingCampaignPageType): boolean => {
  return campaignViewType === EmailMarketingCampaignPageType.DELETED;
};

export const isDefaultCampaignsViewType = (campaignViewType: EmailMarketingCampaignPageType): boolean => {
  return campaignViewType === EmailMarketingCampaignPageType.OWN;
};

export const isSharedCampaignsViewType = (campaignViewType: EmailMarketingCampaignPageType): boolean => {
  return campaignViewType === EmailMarketingCampaignPageType.SHARED_WITH_ME;
};

export const getCampaignPublishWizardUri = (campaign: EmailCampaign): string => {
  return `emailmarketing/publish/campaign/${campaign.id}`;
};

export const getScheduledCampaignPublishWizardUri = (campaign: EmailCampaign): string => {
  return `emailmarketing/publish/campaign/${campaign.id}/scheduled`;
};

export const getRedirectToEmailGalleryURL = (): string => {
  return window.PMW.util.site_url('email/types');
};

export const openCampaignEditingPage = (campaign: EmailCampaign): void => {
  if (campaign.emailTemplateId) {
    window.location.href = window.PMW.util.site_url(`emailmaker/load/${campaign.emailTemplateId}`);
    return;
  }

  if (campaign.posterId) {
    openCampaignInWizard(getCampaignPublishWizardUri(campaign));
  }
};

export const openCampaignInWizard = (redirectURL: string): void => {
  if (isUserPremium()) {
    window.location.href = window.PMW.util.site_url(redirectURL);
    return;
  }

  window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_PUBLISH_EMAIL_CAMPAIGN);
};

export const onCreateNewCampaign = (): void => {
  window.location.href = getRedirectToEmailGalleryURL();
};

export const isDraftCampaign = (campaign: EmailCampaign): boolean => {
  return campaign.state === EmailCampaignState.DRAFT;
};

export const isCampaignDeleted = (campaign: EmailCampaign): boolean => {
  return campaign.isDeleted;
};

export const isCampaignPaused = (campaign: EmailCampaign): boolean => {
  return campaign.isPaused;
};

export const isCampaignSent = (campaign: EmailCampaign): boolean => {
  return campaign.state === EmailCampaignState.SENT || campaign.state === EmailCampaignState.FAKE_SENT;
};

export const isCampaignCurrentlyScheduled = (campaign: EmailCampaign): boolean => {
  return (
    campaign.state !== EmailCampaignState.DRAFT &&
    campaign.state !== EmailCampaignState.SENT &&
    campaign.isScheduled &&
    isUpcomingDate(getDateFromUnixTimestamp(campaign.publishOn))
  );
};

export const isCampaignReadyToSend = (campaign: EmailCampaign): boolean => {
  return campaign.state === EmailCampaignState.READY;
};

export const isCampaignSending = (campaign: EmailCampaign): boolean => {
  return (
    campaign.state === EmailCampaignState.SENDING ||
    campaign.state === EmailCampaignState.BATCH_READY ||
    campaign.state === EmailCampaignState.FAKE_SENDING ||
    campaign.state === EmailCampaignState.WAITING_FOR_RETRY
  );
};

export const isCampaignPreparingToSend = (campaign: EmailCampaign): boolean => {
  return campaign.state === EmailCampaignState.PREPARING_GRAPHIC || campaign.state === EmailCampaignState.VALIDATING_MAILING_LIST;
};

export const isCampaignValidating = (campaign: EmailCampaign): boolean => {
  return campaign.state === EmailCampaignState.UNDER_REVIEW || campaign.state === EmailCampaignState.UNDER_SUSPICION;
};

export const isCampaignPreparingNextBatch = (campaign: EmailCampaign): boolean => {
  return campaign.state === EmailCampaignState.BATCH_PAUSED;
};

export const isCampaignSuspended = (campaign: EmailCampaign): boolean => {
  return campaign.state === EmailCampaignState.SUSPENDED || campaign.state === EmailCampaignState.ADMIN_ARCHIVED || campaign.state === EmailCampaignState.SPAM_CONTENT;
};

export const hasCampaignGenerationFailed = (campaign: EmailCampaign): boolean => {
  return campaign.state === EmailCampaignState.GENERATION_FAILED;
};

export const isCampaignPausable = (campaign: EmailCampaign): boolean => {
  return !campaign.isPaused && (isCampaignPartOfSendingLifeCycle(campaign) || hasCampaignGenerationFailed(campaign));
};

export const isCampaignPartOfSendingLifeCycle = (campaign: EmailCampaign): boolean => {
  return isCampaignSending(campaign) || isCampaignPreparingToSend(campaign) || isCampaignPreparingNextBatch(campaign);
};
export const isCampaignInSendingOrValidationLifecycle = (campaign: EmailCampaign): boolean => {
  return isCampaignPartOfSendingLifeCycle(campaign) || isCampaignReadyToSend(campaign) || isCampaignValidating(campaign);
};
export const hasCampaignPublishOnPassed = (publishOnTimestamp: number): boolean => {
  return !isFutureDate(getDateFromUnixTimestamp(publishOnTimestamp));
};

export const isCampaignPublishTimePastOrApproaching = (campaign: EmailCampaign): boolean => {
  const publishDate = getDateFromUnixTimestamp(campaign.publishOn);
  return hasCampaignPublishOnPassed(campaign.publishOn) || isInThirtyMinutesOrLessFromNow(publishDate);
};

export const getDefaultEmailStatusFilters = (): EmailCampaignStatusFilter => {
  return {
    [EmailCampaignStatus.DRAFT]: true,
    [EmailCampaignStatus.SCHEDULED]: true,
    [EmailCampaignStatus.SENDING]: true,
    [EmailCampaignStatus.PUBLISHED]: true,
    [EmailCampaignStatus.ERROR]: true,
  };
};

export const getDefaultEmailSortingFilter = (): EmailCampaignSortingFilter => {
  return EmailCampaignSorting.PUBLISHING_DATE;
};
export const startSendCampaignNowFlow = (campaignId: string): void => {
  const modalButtons: CoreJsModalButtons = {};

  modalButtons[window.i18next.t('pmwjs_btn_cancel')] = {
    ch: (): void => {
      window.PMW.closeModalDialog(SEND_CAMPAIGN_NOW_MODAL_ID);
    },
    classes: ' secondary back-cta',
  };

  modalButtons[window.i18next.t('pmwjs_send_right_now')] = {
    ch: function () {
      window.PMW.setLoading(window.i18next.t('pmwjs_loading'));
      void executeThunk(
        () => {
          return window.PMW.redux.store.dispatch(sendScheduledCampaignNow(campaignId));
        },
        window.PMW.setLoading.bind(null, null)
      );

      window.PMW.closeModalDialog(SEND_CAMPAIGN_NOW_MODAL_ID);
    },
    classes: '-full-width send-campaign-cta spacing-m-r-6',
  };

  const dialogContent = window.PMW.openModalDialog(SEND_CAMPAIGN_NOW_MODAL_ID, window.i18next.t('pmwjs_send_right_now'), modalButtons, true, '-modal-550', false).find('.content');
  window.PMW.load(dialogContent, window.PMW.util.site_url('emailmarketing/sendEmailCampaignNowModal'), {});
};

export const startUnScheduleCampaignNowFlow = (campaignId: string): void => {
  const modalButtons: CoreJsModalButtons = {};

  modalButtons[window.i18next.t('pmwjs_go_back')] = {
    ch: (): void => {
      window.PMW.closeModalDialog(UNSCHEDULE_CAMPAIGN_MODAL_ID);
    },
    classes: ' secondary back-cta spacing-m-b-2',
  };

  modalButtons[window.i18next.t('pmwjs_yes_cancel')] = {
    ch: (): void => {
      window.PMW.setLoading(window.i18next.t('pmwjs_loading'));
      void executeThunk(
        () => {
          return window.PMW.redux.store.dispatch(unscheduleEmailCampaign(campaignId));
        },
        window.PMW.setLoading.bind(null, null)
      );

      window.PMW.closeModalDialog(UNSCHEDULE_CAMPAIGN_MODAL_ID);
    },
    classes: '-full-width cancel-campaign',
  };

  const dialogContent = window.PMW.openModalDialog(
    UNSCHEDULE_CAMPAIGN_MODAL_ID,
    window.i18next.t('pmwjs_cancelled_scheduled_campaign'),
    modalButtons,
    true,
    '-modal-550',
    false
  ).find('.content');
  window.PMW.load(dialogContent, window.PMW.util.site_url('emailmarketing/cancelScheduledEmailCampaignNowModal'), {});
};

export const areEmailCampaignFiltersInDefaultState = (statusFilter: EmailCampaignStatusFilter, sortingFilter: EmailCampaignSortingFilter): boolean => {
  return sortingFilter === EmailCampaignSorting.PUBLISHING_DATE && areAllStatusesSelectedInFilter(statusFilter);
};

export const areAllStatusesSelectedInFilter = (statusFilter: EmailCampaignStatusFilter): boolean => {
  return (
    Object.values(statusFilter).find((val) => {
      return !val;
    }) === undefined
  );
};

export const getSelectedStatusesInFilter = (statusFilter: EmailCampaignStatusFilter): EmailCampaignStatus[] => {
  return (Object.keys(statusFilter) as EmailCampaignStatus[]).filter((filterKey) => {
    return statusFilter[filterKey];
  });
};
export const getAppropriateEmailCampaignsLazyData = (
  state: EmailMarketingCampaignsReduxState,
  statusFilter: EmailCampaignStatusFilter,
  sortingFilter: EmailCampaignSortingFilter
): EmailCampaignsLazyData => {
  if (!isDefaultCampaignsViewType(state.viewType) || areEmailCampaignFiltersInDefaultState(statusFilter, sortingFilter)) {
    return state.defaultCampaignsLazyData;
  }

  return state.filteredCampaignsLazyData;
};

export const getCampaignPublishStatusText = (campaign: EmailCampaign): string => {
  return isDraftCampaign(campaign) ? window.i18next.t('pmwjs_none_draft') : formatLocalDate(campaign.publishOn);
};

export const copyEmailCampaignHTML = (emailTemplateId?: string): void => {
  if (!emailTemplateId) {
    return;
  }

  window.PMW.setLoading(window.i18next.t('pmwjs_copying'));
  loadExportEmailDependencies(async () => {
    try {
      const response = await getEmailTemplateHTML(emailTemplateId);
      doCopyEmailTemplateHTML(response);
    } catch (err) {
      onAjaxError(err);
    } finally {
      window.PMW.setLoading(null);
    }
  });
};

export const downloadEmailCampaignHTML = (emailTemplateId?: string): void => {
  if (!emailTemplateId) {
    return;
  }

  window.PMW.setLoading(window.i18next.t('pmwjs_downloading'));
  loadExportEmailDependencies(async () => {
    try {
      const response = await getEmailTemplateHTML(emailTemplateId);
      doDownloadEmailTemplateHTML(response);
    } catch (err) {
      onAjaxError(err);
    } finally {
      window.PMW.setLoading(null);
    }
  });
};

export const getMinDateForChangingScheduleTime = (): Date => {
  const minDate = new Date();
  minDate.setMinutes(minDate.getMinutes() + MIN_SCHEDULE_MINUTES_FROM_NOW);
  return minDate;
};

export const openCampaignRescheduledSuccessfulyModal = (newDate: Date): void => {
  const dialogContent = window.PMW.openModalDialog('reschedule-campaign', window.i18next.t('pmwjs_rescheduled_success'), {}, true, '-modal-550', false).find('.content');
  window.PMW.load(dialogContent, window.PMW.util.site_url('emailmarketing/rescheduleEmailCampaignModal'), {
    timestamp: getUnixTimestamp(newDate),
  });
};

export const pollForEmailTemplatePreview = (emailTemplateId: string, onSuccess: (response?: string) => void = noop, onError: VoidFunction = noop): void => {
  window.PMW.pollingAjaxCallAsync(
    window.PMW.readLocal.bind(null, 'emailmarketing/isEmailTemplatePreviewGenerated', {
      emailTemplateId,
    }),
    emailTemplatePreviewPollingFunction.bind(null, emailTemplateId),
    20000
  ).then(onSuccess, onError);
};

const emailTemplatePreviewPollingFunction = async (emailTemplateId: string, data: string | null): Promise<EmailPreviewPollFinishResponse> => {
  if (data) {
    return {
      isAvailable: true,
      data,
    };
  }

  return window.PMW.readLocal('emailmarketing/checkEmailTemplatePreviewGenerationStatus', {
    emailTemplateId,
  }) as Promise<EmailPreviewPollFinishResponse>;
};

const getEmailTemplateHTML = async (emailTemplateId: string): Promise<GetEmailTemplateHTMLResponse> => {
  return (await window.PMW.readLocal('emailmarketing/getEmailTemplateHTML', {
    idEmailTemplate: emailTemplateId,
  })) as Promise<GetEmailTemplateHTMLResponse>;
};

const doCopyEmailTemplateHTML = (response: GetEmailTemplateHTMLResponse): void => {
  window.PMW.copyEmailHTML(response.html, noop, EMAIL_EXPORT_GA_LABEL_MY_STUFF, showCopiedToast);
};

const doDownloadEmailTemplateHTML = (response: GetEmailTemplateHTMLResponse): void => {
  window.PMW.downloadEmailHTML(response.html, response.fileName, EMAIL_EXPORT_GA_LABEL_MY_STUFF);
};

const showCopiedToast = (): void => {
  window.PMW.setLoading(window.i18next.t('pmwjs_copied'), true);
  setTimeout(window.PMW.setLoading.bind(null, null), 3000);
};

const loadExportEmailDependencies = (onLoad: VoidFunction): void => {
  window.PMW.util.require(typeof window.PMW.copyEmailHTML === 'undefined', 'exportEmailTemplate', true, () => {
    onLoad();
  });
};
