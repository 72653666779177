import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {FilterStates} from '@Libraries/email-marketing-campaigns-audience-library';
import {remove} from '@Utils/array.util';
import {emailMarketingCampaignAudienceExtraReducers} from './email-marketing-campaign-audience-extra-reducers';

export interface Audience {
  id: number;
  idMailingList: number;
  fullName?: string;
  phoneNumber?: string;
  email: string;
  state: number;
  displayableBounceReason: string;
  subscribedOn?: number;
}

export interface UpdateFiltersParams {
  mailingListId: number;
  states: FilterStates;
}

export interface AddEmailsToMailingListParams {
  idMailingList: number;
  audiences: Array<Audience>;
  totalEmailsCount?: number;
}

export interface MailingList {
  name: string;
  emailIds: Array<number>;
  totalEmailsCount: number;
  filters: FilterStates;
  searchTerm: string;
  isSpam: boolean;
  lastViewed: number;
}

export interface AudienceState {
  mailingLists: MailingListsMap;
  audiences: AudiencesMap;
  isMailingListRenderComplete: boolean;
  isSignUpFormRenderComplete: boolean;
  mailingListCount: number;
  signUpFormCount: number;
  isMailingListSignUpFormLoading: boolean;
}

export interface SearchTermPayload {
  mailingListId: number;
  searchTerm: string;
}

interface AudiencesMap {
  [key: number]: Audience;
}

interface MailingListsMap {
  [key: number]: MailingList;
}

const initialState: AudienceState = {
  mailingLists: {},
  audiences: {},
  isMailingListRenderComplete: false,
  isSignUpFormRenderComplete: false,
  mailingListCount: 0,
  signUpFormCount: 0,
  isMailingListSignUpFormLoading: true,
};

export interface MailingListSignUpFormCountsPayload {
  mailingListCount: number;
  signUpFormCount: number;
}

const emailCampaignAudienceSlice = createSlice({
  name: 'emailCampaignAudience',
  initialState,
  reducers: {
    addAudiencesToMailingList: (state, {payload}: PayloadAction<AddEmailsToMailingListParams>) => {
      const emailIds = [] as Array<number>;

      payload.audiences.forEach((audience: Audience) => {
        emailIds.push(audience.id);
        state.audiences[audience.id] = audience;
      });

      state.mailingLists[payload.idMailingList].emailIds = [...state.mailingLists[payload.idMailingList].emailIds, ...emailIds];

      if (payload.totalEmailsCount) {
        state.mailingLists[payload.idMailingList].totalEmailsCount = payload.totalEmailsCount;
      }
    },
    updateFilters: (state, {payload}: PayloadAction<UpdateFiltersParams>) => {
      state.mailingLists[payload.mailingListId].filters = {...state.mailingLists[payload.mailingListId].filters, ...payload.states};
    },
    updateSearchTerm: (state, {payload}: PayloadAction<SearchTermPayload>) => {
      state.mailingLists[payload.mailingListId].searchTerm = payload.searchTerm;
    },
    updateIsMailingListRenderComplete: (state, {payload}: PayloadAction<boolean>) => {
      state.isMailingListRenderComplete = payload;
    },
    updateIsSignUpFormRenderComplete: (state, {payload}: PayloadAction<boolean>): void => {
      state.isSignUpFormRenderComplete = payload;
    },
    updateMailingListsSignUpFormsCount: (state, {payload}: PayloadAction<MailingListSignUpFormCountsPayload>): void => {
      state.mailingListCount = payload.mailingListCount;
      state.signUpFormCount = payload.signUpFormCount;
      state.isMailingListSignUpFormLoading = false;
    },
  },
  extraReducers: emailMarketingCampaignAudienceExtraReducers,
});

export const addMailingList = (state: AudienceState, idMailingList: number, data: MailingList): void => {
  state.mailingLists[idMailingList] = data;
};

export const addAudienceIdsToMailingList = (state: AudienceState, idMailingList: number, emailIds: Array<number>): void => {
  state.mailingLists[idMailingList].emailIds = [...state.mailingLists[idMailingList].emailIds, ...emailIds];
};

export const addAudience = (state: AudienceState, email: Audience): void => {
  state.audiences[email.id] = email;
};

export const changeMailingListName = (state: AudienceState, idMailingList: number, newName: string): void => {
  state.mailingLists[idMailingList].name = newName;
};

export const removeMailingList = (state: AudienceState, idMailingList: number): void => {
  if (state.mailingLists[idMailingList]) {
    state.mailingLists[idMailingList].emailIds.map((idEmail: number) => {
      return delete state.audiences[idEmail];
    });
    delete state.mailingLists[idMailingList];
  }
};

export const removeAudience = (state: AudienceState, idMailingList: number, idEmail: number, index: number): void => {
  state.mailingLists[idMailingList].emailIds = remove(state.mailingLists[idMailingList].emailIds, index);
  delete state.audiences[idEmail];
  state.mailingLists[idMailingList].totalEmailsCount -= 1;
};

export const {addAudiencesToMailingList, updateFilters, updateSearchTerm, updateIsMailingListRenderComplete, updateIsSignUpFormRenderComplete, updateMailingListsSignUpFormsCount} =
  emailCampaignAudienceSlice.actions;
export const emailMarketingCampaignAudienceReducer = emailCampaignAudienceSlice.reducer;
