import type {LoadingStates} from '@Utils/loading.util';

export type EmailCampaignId = string;
export type AllCampaigns = Record<EmailCampaignId, EmailCampaign>;

export interface EmailCampaignsBaseProps {
  campaignViewType?: EmailMarketingCampaignPageType;
}

export interface EmailGridItemBase {
  isLoading: boolean;
}

export interface EmailCampaignsContainerQueryResult {
  isExtraSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
}

export enum EmailCampaignState {
  DRAFT = 'draft',
  READY = 'ready',
  SENDING = 'sending',
  BATCH_READY = 'batch_ready',
  BATCH_PAUSED = 'batch_paused',
  UNDER_REVIEW = 'under_review',
  UNDER_SUSPICION = 'under_suspicion',
  WAITING_FOR_RETRY = 'waiting',
  SENT = 'sent',
  SUSPENDED = 'suspended',
  PREPARING_GRAPHIC = 'preparing_graphic',
  SPAM_CONTENT = 'spam_content',
  ADMIN_ARCHIVED = 'admin_archived',
  VALIDATING_MAILING_LIST = 'validating_list',
  GENERATION_FAILED = 'generation_failed',
  GENERATION_FAILED_RETRIES = 'failed_retries',
  FAKE_SENT = 'fake_sent',
  FAKE_SENDING = 'fake_sending',
}

enum CampaignOwnershipType {
  OWN = 'own',
  SHARED_IN_TEAM = 'shared_in_team',
  COLLABORATOR = 'collaborator',
}

export enum EmailMarketingCampaignPageType {
  OWN = 'mine',
  SHARED_WITH_ME = 'shared',
  DELETED = 'trash',
}

export type MailingListNames = string[];

export interface EmailCampaign {
  id: EmailCampaignId;
  name: string;
  subject: string;
  publishOn: number;
  mailingListNames: MailingListNames;
  state: EmailCampaignState;
  isScheduled: boolean;
  isDeleted: boolean;
  isPaused: boolean;
  isEditable: boolean;
  isVideo: boolean;
  imagePreviewURL?: string;
  videoPreviewURL?: string;
  emailTemplateId?: string;
  posterId?: string;
  // codeReviewShahrukhLater: adding this to the VO for when we implement this in collaboration
  ownershipType?: CampaignOwnershipType;
  sharedBy?: string;
  isRenaming?: boolean;
}

export interface EmailPreviewPollServerResponse {
  emailTemplateId: string;
  imagePreview: string;
  videoPreview?: string;
}

export interface EmailPreviewPollFinishResponse {
  isAvailable: boolean;
  data: string;
}

// disabling eslint warning because EmailCampaignStatus is singular, idk why it picks it up as plural
// eslint-disable-next-line local-rules/singular-enums
export enum EmailCampaignStatus {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  SENDING = 'sending',
  PUBLISHED = 'published',
  ERROR = 'error',
}

export enum EmailCampaignSorting {
  LAST_MODIFIED = 'last_modified',
  PUBLISHING_DATE = 'publishing_date',
}

export interface EmailCampaignsLazyData {
  ids: string[];
  loadingState: LoadingStates;
  loadMore: boolean;
}

export type EmailCampaignStatusFilter = Record<EmailCampaignStatus, boolean>;
export type EmailCampaignSortingFilter = EmailCampaignSorting;

export interface EmailMarketingCampaignsReduxState {
  defaultCampaignsLazyData: EmailCampaignsLazyData;
  filteredCampaignsLazyData: EmailCampaignsLazyData;
  allCampaigns: AllCampaigns;
  sortingFilter: EmailCampaignSortingFilter;
  statusFilter: EmailCampaignStatusFilter;
  viewType: EmailMarketingCampaignPageType;
}

export interface FetchEmailCampaignsAjaxParams {
  statusFilter: EmailCampaignStatusFilter;
  sortBy: EmailCampaignSorting;
  offset: number;
  fetchDeleted: boolean;
  viewType: EmailMarketingCampaignPageType;
}

export interface AjaxResponseWithCampaign {
  campaign?: EmailCampaign;
}

export interface DuplicateCampaignResponse {
  emailCampaignId: EmailCampaignId;
  emailTemplateId: string;
}

export interface ChangeScheduleDateTimeParams {
  campaignId: EmailCampaignId;
  newDate: Date;
}

export interface DeleteCampaignParams {
  campaignId: EmailCampaignId;
  deletePermanently?: boolean;
}

export interface PauseAndResumeCampaignHandlers {
  onPauseCampaign: VoidFunction;
  onResumeCampaign: VoidFunction;
}

export interface ToggleCampaignRenameModePayload {
  campaignId: EmailCampaignId;
  isRenaming: boolean;
}